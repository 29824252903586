@use '@angular/material' as mat;

$chip-server: #333;
$chip-set-all: green;
$chip-set-partial: #84c377;
$chip-set-none: #bbb;
$chip-set-halted: #f00;
$chip-set-cluster: rgb(0, 85, 255);
$chip-set-warn: mat.get-color-from-palette(mat.$orange-palette, 800);

$dark-background: #333;
$lighter-dark-background: lighten($dark-background, 15%);
$light-background: white;
$darker-light-background: darken($light-background, 10%);

$menu-background: mat.get-color-from-palette(mat.$grey-palette, 700);
$menu-foreground: mat.get-color-from-palette(mat.$grey-palette, 100);

$color-success: mat.get-color-from-palette(mat.$green-palette, 500);
$color-warning: mat.get-color-from-palette(mat.$orange-palette, 800);
$color-error: mat.get-color-from-palette(mat.$red-palette, 600);

$theme-light-primary: mat.$deep-purple-palette;
$theme-light-accent: mat.$cyan-palette;
$theme-light-warn: mat.$red-palette;
$theme-dark-primary: mat.$lime-palette;
$theme-dark-accent: mat.$cyan-palette;
$theme-dark-warn: mat.$red-palette;

$link-color: mat.get-color-from-palette($theme-light-primary, 500);

@mixin lighter-background($theme) {
	background-color: $menu-background;
	color: $menu-foreground;
}

@mixin lighter-warn($theme) {
	$warn: map-get($theme, warn);
	color: mat.get-color-from-palette($warn, 500);
}

::ng-deep .positive {
	color: $color-success;
}

::ng-deep .negative {
	color: lighten($color-error, 10);
}

$bf-back-color: #a6d8ff;
$bf-back-color-dark: #002c50;
$bf-lay-color: #fac9d1;
$bf-lay-color-dark: #510713;
$bf-suspended-color: #b30000;
$bf-suspended-color-dark: #b30000;
$bf-highlight-color: #f1e100;
$bf-highlight-color-dark: #8f6709;

$color-bf-logging: purple;
$color-all-logging: linear-gradient(to left, purple 50%, $color-warning 50%);
