@use '@angular/material' as mat;
@import "variables.scss";
// @import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/* You can add global styles to this file, and also import other style files */
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// @import '@angular/material/theming';
@include mat.core();

$app-primary: mat.define-palette($theme-light-primary);
$app-accent: mat.define-palette($theme-light-accent);
$app-warn: mat.define-palette($theme-light-warn);
$app-theme: mat.define-light-theme($app-primary, $app-accent);

@include mat.all-component-themes($app-theme);

.dark-theme {
    $app-primary: mat.define-palette($theme-dark-primary);
    $app-accent: mat.define-palette($theme-dark-accent);
    $app-warn: mat.define-palette($theme-dark-warn);
    $app-theme: mat.define-dark-theme($app-primary, $app-accent);

	$link-color: mat.get-color-from-palette($theme-dark-primary, 500);

	@include mat.all-component-themes($app-theme);

	.gv-menu-expand.mat-expansion-panel,
	.mat-menu-panel {
		@include lighter-background($app-theme);
	}

	a {
		color: $link-color;
	}

	color: white;
}

.mat-warn-color {
	@include lighter-warn($app-theme);
}

html, body {
	height: 100%;
	// overflow: hidden;
}

.container {
    padding: 24px;
}

mat-header-row {
    min-height: 32px;
}

.betInfo-window {
	width: 750px;
	height: 750px;
}

.mat-standard-chip {
	min-height: 20px !important;
	min-width: 20px !important;
	padding: 2px 5px !important;
}

.mat-tooltip__multiline {
	text-align: left !important;
	white-space: pre !important;
	max-width: fit-content !important;
}
